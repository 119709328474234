@use "src/styles/typography";
@use "src/styles/palette";
@use "src/styles/layout";

.icon_slider {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  overflow: hidden;
  background: var(--grey-light);
  padding: 3.5rem 1rem;

  @media (min-width: layout.breakpoint(md)) {
    background: transparent;
    padding: 0;
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    @media (min-width: layout.breakpoint(md)) {
      color: #000;
      text-align: center;
      align-items: center;
      justify-content: center;
    }

    .title {
      @extend .header_2;
    }
    .description {
      max-width: 548px;
      @extend .body;
    }
  }

  .carousel {
    width: 100vw;
    position: relative;
    justify-content: center;
    align-items: center;
    display: flex;

    @media (min-width: layout.breakpoint(md)) {
      width: calc(100% + 4rem);
      left: -2rem;
      margin: 5% 0;
    }

    .slider {
      @media (min-width: layout.breakpoint(md)) {
        transform: rotate(8deg);
      }
    }

    .background {
      position: absolute;
      height: 100%;
      width: 100%;
      z-index: -1;
      top: 0;

      img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  .controls {
    @include layout.container(xl);
    position: relative;
    width: 100%;
    height: 4rem;
    display: none;

    @media (min-width: layout.breakpoint(md)) {
      display: flex;
      justify-content: space-between;
    }

    .button {
      display: none;
      
      &:disabled {
        display: none;
      }

      @media (min-width: layout.breakpoint(md)) {
        display: grid;
      }
    }
  }

  .cta {
    display: flex;
    width: fit-content;

    @media (min-width: layout.breakpoint(md)) {
      align-self: center;
    }
  }
}

[data-theme="750"] {
  .icon_slider {
    background: linear-gradient(
      var(--750-sunny-orange),
      var(--750-light-green)
    );

    @media (min-width: layout.breakpoint(md)) {
      background: transparent;
    }
  }
  .cta {
    color: #000;

    @media (max-width: layout.breakpoint(md)) {
      color: #fff;
      background:
        linear-gradient(black, black) padding-box,
        var(--750-gradient-02) border-box;
    }
  }
}
